<template>
  <validation-observer
    ref="activitiesForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit($emit('save-activity'))">
      <validation-provider
        v-if="$route.name === 'AddActivity' && Object.keys($route.query).length === 0"
        v-slot="{ errors }"
        rules="required"
        name="parentType"
      >
        <b-field
          horizontal
          label="Parent Type"
          label-for="parentType"
          class="is-required"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-select
            id="parentType"
            v-model="parentType"
          >
            <option
              v-for="(option, index) in ['Account', 'Contact', 'Lead', 'Opportunity']"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="parent"
      >
        <b-field
          v-if="$route.name === 'AddActivity'"
          horizontal
          label="Parent / Account"
          label-for="parent"
          class="is-required"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-autocomplete
            v-if="parentType"
            v-model="parent"
            icon-right="search"
            :field="parentSearchField"
            :data="parentSearchResults"
            :loading="isLoading"
            @typing="getParentAsyncData"
            @select="(option) => (selectedParent = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option[parentSearchField].trim() | capitalize }}
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ parent }}
            </template>
          </b-autocomplete>

          <b-input
            v-else
            id="parent"
            :value="parent"
            disabled
          />
        </b-field>
      </validation-provider>

      <b-field
        v-if="$route.name === 'EditActivity'"
        horizontal
        label="Parent"
        label-for="parent"
      >
        <b-input
          id="parent"
          :value="$store.state.Activities.activity.parent"
          disabled
        />
      </b-field>

      <validation-provider
        v-if="$route.name === 'AddActivity'"
        v-slot="{ errors }"
        rules="required"
        name="type"
      >
        <b-field
          horizontal
          label="Activity Type"
          label-for="activityType"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-select
            id="activityType"
            v-model="activityTypeId"
          >
            <option
              v-for="(activityType, index) in activityTypes"
              :key="index"
              :value="activityType.id"
            >
              {{ activityType.value }}
            </option>
          </b-select>
        </b-field>
      </validation-provider>

      <!-- TODO: Enable when the back-end API is fixed -->

      <!--       <validation-provider
        v-if="getSelectedActivityTypeCode() === 'event'"
        v-slot="{ errors }"
        rules="required"
        name="eventType"
      >
        <b-field
          horizontal
          label="Event Type"
          label-for="eventType"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-select
            id="eventType"
            v-model="eventTypeId"
          >
            <option
              v-for="(eventType, index) in eventTypes"
              :key="index"
              :value="eventType.id"
            >
              {{ eventType.value }}
            </option>
          </b-select>
        </b-field>
      </validation-provider> -->

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="name"
      >
        <b-field
          horizontal
          label="Activity Name"
          label-for="activityName"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            id="activityName"
            v-model="activityName"
          />
        </b-field>
      </validation-provider>

      <b-field
        horizontal
        label="Description"
      >
        <b-input
          v-model="description"
          type="textarea"
        />
      </b-field>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="owner"
      >
        <b-field
          horizontal
          label="Owner"
          label-for="owner"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <div class="columns">
            <div class="column is-half">
              <b-autocomplete
                id="owner"
                v-model="owner"
                icon-right="search"
                field="fullName"
                :data="ownerSearchResults"
                :loading="isLoading"
                @typing="getOwnersAsyncData"
                @select="(option) => (selectedOwner = option)"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.fullName.trim() | capitalize }}
                      <br>
                      <small>
                        email: {{ props.option.email }} <br>
                        Phone: {{ props.option.phoneCell }}
                      </small>
                    </div>
                  </div>
                </template>

                <template #empty>
                  No results for {{ owner }}
                </template>
              </b-autocomplete>
            </div>
          </div>
        </b-field>
      </validation-provider>

      <b-field
        v-if="getSelectedActivityTypeCode() === 'task'"
        horizontal
        label="Priority"
        label-for="priority"
      >
        <div class="columns">
          <div class="column is-half">
            <b-select
              id="priority"
              v-model="taskPriorityId"
              expanded
            >
              <option
                v-for="(taskPriority, index) in taskPriorities"
                :key="index"
                :value="taskPriority.id"
              >
                {{ taskPriority.value }}
              </option>
            </b-select>
          </div>
        </div>
      </b-field>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="task type"
      >
        <b-field
          v-if="getSelectedActivityTypeCode() === 'task'"
          horizontal
          label="Task Type"
          label-for="taskType"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-select
            id="taskType"
            v-model="taskTypeId"
          >
            <option
              v-for="(taskType, index) in taskTypes"
              :key="index"
              :value="taskType.id"
            >
              {{ taskType.value }}
            </option>
          </b-select>
        </b-field>
      </validation-provider>

      <!-- TODO: Enable on back-end API creation -->

      <!-- <b-field
        v-if="getSelectedActivityTypeCode() === 'task' || getSelectedActivityTypeCode() === 'event'"
        horizontal
        label="Schedule Reminders"
      >
        <b-button
          type="is-info"
          icon-left="bell"
          @click="$emit('open-notifications-modal')"
        >
          notifications
        </b-button>
      </b-field> -->

      <b-field
        v-if="getSelectedActivityTypeCode() === 'task' || getSelectedActivityTypeCode() === 'event'"
        horizontal
        label="Is Complete"
        label-for="isComplete"
      >
        <b-checkbox
          id="isComplete"
          v-model="isComplete"
          expanded
        />
      </b-field>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="due date"
      >
        <b-field
          v-if="getSelectedActivityTypeCode() === 'task'"
          horizontal
          label="Due Date"
          label-for="dueDate"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <div class="columns">
            <div class="column is-half">
              <b-input
                id="dueDate"
                v-model="dueDate"
                icon="calendar"
                type="datetime-local"
              />
            </div>
          </div>
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="timezone"
      >
        <b-field
          v-if="getSelectedActivityTypeCode() === 'event'"
          horizontal
          label="Timezone"
          label-for="timezone"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <div class="columns">
            <div class="column is-half">
              <b-select
                v-model="timezoneId"
              >
                <option
                  v-for="(timezone, index) in timezones"
                  :key="index"
                  :value="timezone.id"
                >
                  {{ timezone.value }}
                </option>
              </b-select>
            </div>
          </div>
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="date"
      >
        <b-field
          v-if="getSelectedActivityTypeCode() === 'event'"
          horizontal
          label="Date"
          label-for="date"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <div class="columns">
            <div class="column is-half">
              <b-input
                id="date"
                v-model="meetingDate"
                type="date"
                icon="calendar"
              />
            </div>
          </div>
        </b-field>
      </validation-provider>

      <b-field
        v-if="getSelectedActivityTypeCode() === 'event'"
        horizontal
      >
        <div class="columns">
          <div class="column is-half">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="start time"
            >
              <b-field
                label="Start Time"
                label-for="startTime"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <b-input
                  v-model="meetingStartTime"
                  type="time"
                />
              </b-field>
            </validation-provider>
          </div>

          <div class="column">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="end time"
            >
              <b-field
                label="End Time"
                label-for="endTime"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <b-input
                  v-model="meetingEndTime"
                  type="time"
                />
              </b-field>
            </validation-provider>
          </div>
        </div>
      </b-field>

      <b-field
        v-if="getSelectedActivityTypeCode() === 'event'"
        label="Invited"
        label-for="invited"
        horizontal
        grouped
      >
        <b-autocomplete
          id="invited"
          v-model="inviteeName"
          icon-right="search"
          field="fullName"
          :data="inviteeSearchResults"
          :loading="isLoading"
          @typing="getInviteeAsyncData"
          @select="(option) => selectedInvitee = option"
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                {{ props.option.fullName.trim() | capitalize }}
                <br>
                <small>
                  email: {{ props.option.email }} <br>
                  Phone: {{ props.option.phoneCell }}
                </small>
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>

      <template v-if="getSelectedActivityTypeCode() === 'event'">
        <b-field
          v-for="(invitee, index) in invited"
          :key="index"
          horizontal
        >
          <div class="columns">
            <div class="column">
              <template v-if="$route.name === 'EditActivity'">
                {{ `${invitee.user.firstName} ${invitee.user.lastName}` }}
              </template>
              <template v-else>
                {{ `${invitee.firstName} ${invitee.lastName}` }}
              </template>
            </div>

            <div class="column is-narrow">
              <b-button
                icon-right="close"
                type="is-danger"
                @click="
                  $route.name === 'EditActivity'
                    ? deleteActivityAttendee(invitee.id)
                    : removeInvitee(invitee.userId)
                "
              />
            </div>
          </div>
        </b-field>
      </template>

      <b-field
        v-if="documents.length > 0"
        label="Documents"
        horizontal
      >
        <span
          v-for="(document, index) in documents"
          :key="index"
          class="is-flex is-align-items-center"
        >
          <b-icon
            icon="paperclip"
            class="has-text-info mr-2"
            size="is-small"
          />
          <span
            class="has-text-info is-pointer has-text-weight-bold"
          >
            {{ document.fileName }}
          </span>
          <b-button
            type="is-text"
            class="has-text-grey mx-4"
            icon-right="download"
            size="is-small"
            @click="
              downloadDocument({
                fileName: document.fileName,
                documentId: document.id,
              })
            "
          />
        </span>
      </b-field>

      <b-field>
        <b-button
          :disabled="invalid || loading"
          native-type="submit"
          type="is-success"
          icon-left="save"
        >
          save activity
        </b-button>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import debounce from '@/utils/debounce';

export default {
  name: 'ActivitiesForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    activityTypes: {
      type: Array,
      required: true,
    },

    eventTypes: {
      type: Array,
      required: true,
    },

    invited: {
      type: Array,
      required: true,
    },

    taskTypes: {
      type: Array,
      required: true,
    },

    taskPriorities: {
      type: Array,
      required: true,
    },

    timezones: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    parentType: null,
    parent: '',
    parentSearchField: '',
    inviteeName: '',
    isLoading: false,
    selectedOwner: null,
    selectedInvitee: null,
    selectedParent: null,
    parentSearchResults: [],
    ownerSearchResults: [],
    inviteeSearchResults: [],
  }),

  computed: {
    ...mapGetters({
      loading: 'Activities/getLoading',
      userTimezone: 'Authentication/getTimezone',
    }),

    activityTypeId: {
      get() {
        return this.$store.state.Activities.activity.activityTypeId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_TYPE_ID', value);
      },
    },

    eventTypeId: {
      get() {
        return this.$store.state.Activities.activity.eventTypeId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_EVENT_TYPE_ID', value);
      },
    },

    activityName: {
      get() {
        return this.$store.state.Activities.activity.activityName;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_NAME', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Activities.activity.description;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_DESCRIPTION', value);
      },
    },

    owner: {
      get() {
        return this.$store.state.Activities.activity.owner;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_OWNER', value);
      },
    },

    taskPriorityId: {
      get() {
        return this.$store.state.Activities.activity.taskPriorityId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_TASK_PRIORITY_ID', value);
      },
    },

    taskTypeId: {
      get() {
        return this.$store.state.Activities.activity.taskTypeId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_TASK_TYPE_ID', value);
      },
    },

    isComplete: {
      get() {
        return this.$store.state.Activities.activity.isComplete;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_IS_COMPLETE', value);
      },
    },

    dueDate: {
      get() {
        const dueDateValue = this.$store.state.Activities.activity.dueDate;
        if (dueDateValue) {
          /*
           * Return the date time string representation of dueDate string value
           * to be displayed on the date time input form field
          */
          return dueDateValue.split(' ').join('T');
        }
        return dueDateValue;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_DUE_DATE', value);
      },
    },

    timezoneId: {
      get() {
        return this.$store.state.Activities.activity.timezoneId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_TIMEZONE_ID', value);
      },
    },

    meetingDate: {
      get() {
        return this.$store.state.Activities.activity.meetingDate;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_MEETING_DATE', value);
      },
    },

    meetingStartTime: {
      get() {
        return this.$store.state.Activities.activity.meetingStartTime;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_MEETING_START_TIME', value);
      },
    },

    meetingEndTime: {
      get() {
        return this.$store.state.Activities.activity.meetingEndTime;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_MEETING_END_TIME', value);
      },
    },

    documents: {
      get() {
        return this.$store.state.Activities.activity.documents;
      },
    },
  },

  watch: {
    parentType(value) {
      switch (value) {
        case 'Contact':
          this.parentSearchField = 'contactName';
          break;

        default:
          this.parentSearchField = 'name';
          break;
      }
    },

    selectedOwner(value) {
      return this.$store.commit('Activities/SET_ACTIVITY_OWNER_ID', value.userId);
    },

    selectedParent(value) {
      switch (this.parentType) {
        case 'Account':
          this.$store.commit('Activities/SET_ACTIVITY_ACCOUNT_ID', value.id);
          break;

        case 'Contact':
          this.$store.commit('Activities/SET_ACTIVITY_CONTACT_ID', value.id);
          break;

        case 'Lead':
          this.$store.commit('Activities/SET_ACTIVITY_LEAD_ID', value.id);
          break;

        case 'Opportunity':
          this.$store.commit('Activities/SET_ACTIVITY_OPPORTUNITY_ID', value.id);
          break;

        default:
          break;
      }
    },

    selectedInvitee(value) {
      if (value.userId) {
        if (this.$route.name === 'EditActivity') {
          this.createActivityAttendee(value);
        } else {
          this.addInvitee(value);
        }
      }
    },

    activityTypeId(value) {
      if (this.getActivityTypeCodeById(value) !== 'task') {
        this.taskPriorityId = '';
      }
    },
  },

  created() {
    if (this.$route.name === 'AddActivity') {
      this.$store.commit('Activities/SET_ACTIVITY_TIMEZONE_ID', parseInt(this.userTimezone, 10));
    }
  },

  mounted() {
    if (this.$route.name === 'AddActivity' && Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.accountId) {
        this.$store.commit('Activities/SET_ACTIVITY_ACCOUNT_ID', this.$route.query.accountId);
        this.parent = `Account - ${this.$route.query.account}`;
      }

      if (this.$route.query.contactId) {
        this.$store.commit('Activities/SET_ACTIVITY_CONTACT_ID', this.$route.query.contactId);
        this.parent = `Contact - ${this.$route.query.contact}`;
      }

      if (this.$route.query.leadId) {
        this.$store.commit('Activities/SET_ACTIVITY_OPPORTUNITY_ID', this.$route.query.leadId);
        this.parent = `Lead - ${this.$route.query.lead}`;
      }

      if (this.$route.query.opportunityId) {
        this.$store.commit(
          'Activities/SET_ACTIVITY_OPPORTUNITY_ID',
          this.$route.query.opportunityId,
        );
        this.parent = `Opportunity - ${this.$route.query.opportunity}`;
      }
    }
  },

  destroyed() {
    this.$store.commit('Activities/CLEAR_ACTIVITY');
  },

  methods: {
    getSelectedActivityTypeCode() {
      if (!this.activityTypeId) return null;
      if (!this.activityTypes) return null;
      const activityType = this.activityTypes.find(({ id }) => id === this.activityTypeId);
      if (!activityType) return null;
      return activityType.code;
    },

    getActivityTypeCodeById(value) {
      if (!value) return null;
      if (!this.activityTypes) return null;
      const activityType = this.activityTypes.find(({ id }) => id === value);
      if (!activityType) return null;
      return activityType.code;
    },

    getParentAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        let dispatchAction = '';
        let searchObject = { name: token };
        switch (this.parentType) {
          case 'Account':
            dispatchAction = 'fetchAccounts';
            break;

          case 'Contact':
            dispatchAction = 'fetchContacts';
            searchObject = { contactName: token };
            break;

          case 'Lead':
            dispatchAction = 'fetchLeads';
            break;

          case 'Opportunity':
            dispatchAction = 'fetchOpportunities';
            break;

          default:
            break;
        }

        const response = await this.$store.dispatch(`DataGrids/${dispatchAction}`, {
          page: 0,
          size: 50,
          sort: ['createdAt', 'desc'],
          filters: `&${new URLSearchParams(searchObject).toString()}`,
        });
        this.parentSearchResults = response;
      } catch (error) {
        this.parentSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.ownerSearchResults = response;
      } catch (error) {
        this.ownerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getInviteeAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.inviteeSearchResults = response;
      } catch (error) {
        this.inviteeSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    addInvitee(invitee) {
      try {
        this.$store.commit('Activities/ADD_ACTIVITY_INVITEE', {
          userId: invitee.userId,
          firstName: invitee.firstName,
          lastName: invitee.lastName,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.inviteeName = '';
      }
    },

    /**
     * removeInvitee
     *
     * @param {String} invitee
     */
    removeInvitee(invitee) {
      this.$store.commit('Activities/REMOVE_ACTIVITY_INVITEE', invitee);
    },

    /**
     * createActivityAttendee
     *
     */
    async createActivityAttendee(invitee) {
      try {
        await this.$store.dispatch('Activities/createActivityAttendee', {
          activityId: this.$route.params.activityId,
          userId: invitee.userId,
        });
        this.$store.commit('Activities/ADD_ACTIVITY_INVITEE', {
          userId: invitee.userId,
          firstName: invitee.firstName,
          lastName: invitee.lastName,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.inviteeName = '';
      }
    },

    /**
     * deleteActivityAttendee
     *
     * @param {object} payload
     */
    async deleteActivityAttendee(payload) {
      try {
        await this.$store.dispatch('Activities/deleteActivityAttendee', {
          activityId: this.$route.params.activityId,
          attendeeId: payload,
        });
        await this.$store.dispatch('Activities/fetchActivity', this.$route.params.activityId);
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * downloadDocument
     *
     * @param {object} payload
     */
    async downloadDocument(payload) {
      try {
        await this.$store.dispatch('Documents/downloadDocument', payload);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
