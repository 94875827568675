<template>
  <div>
    <template v-for="(activity, index) in activities.content">
      <div :key="index">
        <div class="level is-mobile level-is-shrinkable">
          <div class="level-left">
            <div class="level-item">
              <div class="columns">
                <div class="column is-narrow">
                  <div class="columns is-mobile">
                    <div class="column is-narrow">
                      <div>
                        <b-icon
                          v-if="activity.isComplete"
                          icon="check"
                          type="is-white"
                          class="is-activity-complete-icon"
                        />

                        <template v-else>
                          <b-icon
                            v-if="activity.activityType === 'Task'"
                            icon="thumbtack"
                            type="is-white"
                            class="is-activity-icon"
                          />

                          <b-icon
                            v-if="activity.activityType === 'Note'"
                            icon="sticky-note"
                            type="is-white"
                            class="is-activity-icon"
                          />

                          <b-icon
                            v-if="activity.activityType === 'Event'"
                            icon="handshake"
                            type="is-white"
                            class="is-activity-icon"
                          />
                        </template>
                      </div>
                    </div>

                    <div class="column is-narrow">
                      <figure class="image is-48x48">
                        <img
                          style="width:48px; height:48px;"
                          class="is-rounded"
                          :src="activity.ownerPhoto"
                        >
                      </figure>
                    </div>
                  </div>
                </div>

                <div class="column has-text-grey">
                  <div class="content">
                    <p>
                      <strong class="has-text-info is-capitalized">
                        {{ activity.owner }}
                      </strong>
                      added a new
                      <strong class="has-text-info is-capitalized">
                        {{ activity.activityType }} {{ activity.activityName }}
                      </strong>
                    </p>
                    <small>
                      {{ activity.createdAt | date }}
                      <small>
                        {{ activity.createdAt | time }}
                      </small>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <b-dropdown aria-role="list">
                <b-button
                  slot="trigger"
                  type="is-text"
                  icon-right="ellipsis-v"
                  class="has-text-grey"
                />

                <template v-if="!activity.isComplete">
                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="$emit('mark-as-complete', {
                      activityId: activity.id,
                      perPage,
                      current
                    })"
                  >
                    <b-icon icon="check" />
                    <span class="has-text-weight-semibold">Mark Completed</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  class="has-text-grey"
                  @click="$router.push(`/activities/${activity.id}/edit`)"
                >
                  <b-icon icon="calendar" />
                  <span class="has-text-weight-semibold">Edit Activity</span>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                  aria-role="listitem"
                  class="has-text-grey"
                  @click="$emit('remove-activity', {
                    activityId: activity.id,
                    perPage,
                    current
                  })"
                >
                  <b-icon icon="close" />
                  <span class="has-text-weight-semibold">Remove</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <hr class="divider">
      </div>
    </template>

    <b-pagination
      v-model="current"
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      @change="onPageChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActivitesList',

  data: () => ({
    perPage: 50,
    total: 0,
    current: 1,
  }),

  computed: mapGetters({
    activities: 'DataGrids/getActivitiesList',
    loading: 'DataGrids/getLoading',
  }),

  mounted() {
    this.fetchActivities();
  },

  methods: {
    async fetchActivities() {
      try {
        await this.$store.dispatch('DataGrids/fetchActivitiesList', {
          page: this.current - 1,
          size: this.perPage,
          sort: ['updatedAt', 'desc'],
        });
        this.total = this.activities.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    onPageChange(page) {
      this.current = page;
      this.fetchActivities();
    },
  },
};
</script>

<style scoped>
.is-activity-icon {
  border-radius: 50%;
  background: #3b90d3;
  width: 48px;
  height: 48px;
}

.is-activity-complete-icon {
  border-radius: 50%;
  background: #a3cc39;
  width: 48px;
  height: 48px;
}

.level-is-shrinkable .level-left,
.level-is-shrinkable .level-right,
.level-is-shrinkable .level-item {
  flex-shrink: 1;
}
</style>
