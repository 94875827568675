<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="calendar" />
        notifications
      </p>

      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-white"
        @click="$emit('close')"
      />
    </header>

    <div class="card-content">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
      />

      <validation-observer
        ref="notificationModalForm"
        v-slot="{ invalid, handleSubmit }"
      >
        <form @submit.prevent="handleSubmit($emit('save-notification'))">
          <validation-provider>
            <b-field
              class="is-primary"
              label="Remind At"
              grouped
            >
              <b-select
                v-model="remindAtId"
                expanded
              >
                <option
                  v-for="(option, index) in reminderAt"
                  :key="index"
                  :value="option.id"
                >
                  {{ option.value }}
                </option>
              </b-select>

              <div
                v-if="remindAtId"
                class="control"
              >
                <b-button
                  icon-right="close"
                  type="is-danger"
                  @click="clearRemindAtId"
                />
              </div>
            </b-field>
          </validation-provider>

          <validation-provider>
            <b-field
              class="is-primary"
              label="Recurring Frequency (default to none)"
              grouped
            >
              <b-select
                v-model="recurringFrequencyId"
                expanded
              >
                <option
                  v-for="(recurringFrequency, index) in recurringFrequencies"
                  :key="index"
                  :value="recurringFrequency.id"
                >
                  {{ recurringFrequency.value }}
                </option>
              </b-select>

              <div
                v-if="recurringFrequencyId"
                class="control"
              >
                <b-button
                  icon-right="close"
                  type="is-danger"
                  @click="clearRecurringFrequencyId"
                />
              </div>
            </b-field>
          </validation-provider>

          <b-field class="is-spaced-top">
            <b-button
              :disabled="invalid"
              icon-left="save"
              type="is-success"
              native-type="submit"
            >
              save
            </b-button>
          </b-field>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'NotificationsFormModal',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    isLoading: true,
  }),

  computed: {
    ...mapGetters({
      recurringFrequencies: 'Lookups/getRecurringFrequencies',
      reminderAt: 'Lookups/getReminderAt',
    }),

    recurringFrequencyId: {
      get() {
        return this.$store.state.Activities.activity.reminder.recurringFrequencyId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_REMINDER_RECURRING_FREQUENCY_ID', value);
      },
    },

    remindAtId: {
      get() {
        return this.$store.state.Activities.activity.reminder.remindAtId;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_REMINDER_REMIND_AT_ID', value);
      },
    },
  },

  async created() {
    try {
      await this.fetchRecurringFrequencies();
      await this.fetchReminderAt();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchRecurringFrequencies: 'Lookups/fetchRecurringFrequencies',
      fetchReminderAt: 'Lookups/fetchReminderAt',
    }),

    clearRemindAtId() {
      return this.$store.commit('Activities/SET_ACTIVITY_REMINDER_REMIND_AT_ID', '');
    },

    clearRecurringFrequencyId() {
      return this.$store.commit('Activities/SET_ACTIVITY_REMINDER_RECURRING_FREQUENCY_ID', '');
    },
  },

};
</script>

<style>

</style>
