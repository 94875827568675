<template>
  <div class="card is-success">
    <header class="card-header">
      <p class="card-header-title">
        notes
      </p>
    </header>

    <div class="card-content">
      <fieldset>
        <b-field
          label="Title"
          class="is-required is-light"
        >
          <b-input v-model="activityName" />
        </b-field>

        <b-field
          label="Description"
          class="is-required is-light"
        >
          <b-input
            v-model="description"
            placeholder="Start typing ..."
            type="textarea"
            icon-right="asterisk"
          />
        </b-field>

        <div class="level is-mobile has-background-white">
          <div class="level-left">
            <div class="level-item">
              <b-field class="file is-white">
                <b-upload
                  v-model="file"
                  class="file-label"
                >
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="file-cta">
                          <b-icon
                            class="file-icon"
                            icon="paperclip"
                          />
                        </span>
                      </div>
                    </div>

                    <div class="level-right">
                      <div class="level-item">
                        <span class="has-text-grey has-text-weight-semibold">
                          {{ fileName }}
                        </span>
                      </div>
                    </div>
                  </div>
                </b-upload>
              </b-field>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <b-field>
                <b-button
                  type="is-primary"
                  @click="$emit('submit-note')"
                >
                  submit
                </b-button>
              </b-field>
            </div>
          </div>
        </div>

        <div
          v-for="(note, index) in notes"
          :key="index"
          class="media is-spaced-top"
        >
          <div class="media-content">
            <div class="content">
              <p class="has-text-grey">
                <strong>{{ note.activityName }}</strong>
                <br>
                {{ note.description.toLowerCase() }}
                <br>
                <small class="has-text-primary">
                  {{ note.createdAt | date }}
                </small>
                <br>
              </p>
            </div>
          </div>

          <div class="media-right">
            <div class="content">
              <b-button
                icon-right="trash"
                class="has-text-grey"
                type="is-text"
                @click="$emit('delete-note', note.id)"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotesCardForm',

  filters: {
    date(value) {
      return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString(
        'en-US',
      )}`;
    },
  },

  props: {
    notes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    activityName: {
      get() {
        return this.$store.state.Activities.activity.activityName;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_NAME', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Activities.activity.description;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_DESCRIPTION', value);
      },
    },

    file: {
      get() {
        return this.$store.state.Activities.activity.file.file;
      },

      set(value) {
        this.$store.commit('Activities/SET_ACTIVITY_FILE_FILE_NAME', value.name);
        return this.getBase64(value, (imageUrl) => {
          this.$store.commit('Activities/SET_ACTIVITY_FILE_FILE', imageUrl);
        });
      },
    },

    fileName() {
      return this.$store.state.Activities.activity.file.fileName;
    },
  },

  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
  },
};
</script>

<style lang="css" scoped></style>
